<template>
  <div class="view">
    <div class="view-heading">
      <h3 class="view-title">{{ $t('system.members') }}</h3>
    </div>
    <div
        class="view-body"
        v-if="model"
    >
      <template v-for="(items, index) in model">
        <div v-bind:key="index">
          <p class="m-0">{{ index }}</p>
          <ul class="overview-users">
            <li
                v-bind:key="index + '_' + key"
                v-for="(item, key) in items"
            >
              <span
                  class="d-flex align-items-center"
                  v-if="item.group"
              >
                <span>
                  <svg-icon icon-class="peoples" />
                </span>
                <span
                    class="d-flex p-2"
                    v-if="item.deleted_at"
                >{{ item.name }}</span>
                <router-link
                    :to="'/administration/group/' + item.id"
                    class=" p-2"
                    v-if="!item.deleted_at"
                >
                  {{ item.name }}
                </router-link>
              </span>
              <span
                  class="d-flex align-items-center"
                  v-else-if="!item.group"
              >
                <span>
                  <img
                      :alt="item.name"
                      :src="'data:image/jpeg;base64,'+item.avatar"
                      class="avatar--ifImage"
                      v-if="item.avatar"
                  >
                  <span
                      class="avatar--ifNotImage"
                      v-else
                  >
                    {{ abbreviation(item.name) }}
                  </span>
                </span>
                <span
                    class="d-flex p-2"
                    v-if="item.deleted_at"
                >{{ item.name }}</span>
                <router-link
                    :to="'/administration/user/' + item.id"
                    class=" p-2"
                    v-if="!item.deleted_at"
                >
                  {{ item.name }}
                </router-link>
              </span>
            </li>
          </ul>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import _ from 'lodash';
import notification from '../../notification/notify';
import {abbreviation} from '../../helpers/functions';

export default {
  name: 'Members',
  components: {},
  data() {
    return {
      model: {},
      resource: 'project',
      redirect: '/',
      loading: true,
    };
  },
  // watch: {
  // '$route': function () {
  //         this.fetchData();
  //     }
  // },
  beforeMount() {
    this.fetchData();
  },
  methods: {
    abbreviation(str) {
      return abbreviation(str);
    },
    async fetchData() {
      let vm = this;
      vm.loading = true;
      await axios.get(`${this.resource}/member/${vm.$route.params.projectId}`)
          .then(function (response) {
            if (response.data.model !== null) {
              //sort data by object keys
              var ordered = {};
              var roles = response.data.model;
              _(roles).keys().sort().each(function (key) {
                ordered[key] = roles[key];
              });
              roles = ordered;
              Vue.set(vm.$data, 'model', roles);
            }
            vm.loading = false;
          })
          .catch(function (error) {
            notification.notify(
                vm.$t('notify.error'),
                error.response.data.error,
                'error');
          });
    },
  },
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
ul {
  &:last-child {
    margin: 0;
  }

  li {
    border-bottom: 1px solid #e6e9ef;

    &:last-child {
      border: none;
    }
  }
}
</style>
